import React from "react"
import Styled from "../styled.module.css"
function Services({ item }) {
  return (
    <div className={Styled.service}>
      <img
        alt="Services"
        src={require(`../../../assets/img/${item.icon}.svg`)}
      ></img>
      <div>
        <h1 className="sm-text bold color-secondary-blue">{item.title}</h1>
        <h2 className={`${Styled.content} sm-text`}>{item.content}</h2>
      </div>
    </div>
  )
}

export default Services
