import React from "react"
import { Link } from "gatsby"
import Service from "./Service"
import Pattern from "../../assets/img/Pattern.svg"
import Styled from "./styled.module.css"

function Services() {
  let services = [
    {
      title: "Business process outsourcing",
      icon: "group",
      content:
        "Increase your productivity, streamline your process, achieve efficiency and save money through outsourcing.",
    },
    {
      title: "Robotic process automation",
      icon: "thunder",
      content:
        "We have built readymade bots to automate your processes especially in high volume accounting, taxation and payroll",
    },
    {
      title: "Software & Design",
      icon: "desktop",
      content:
        "Get experienced team of software designers, developers and testers for any idea you can dream up.",
    },
  ]
  return (
    <section className={Styled.services} id="services">
      <div className={`${Styled.wrapper} container`}>
        <div className={Styled.serviceWrapper}>
          {services.map((i, k) => (
            <Service key={k} item={i} />
          ))}
        </div>
        <div className={Styled.serviceTextWrapper}>
          <div className={Styled.pattern}>
            <img alt="Pattern" src={Pattern}></img>
          </div>
          <h1 className="md-title bold">
            Powerful<br />services that<br />plugin to your business
          </h1>
          <div className={Styled.divider} />
          <h2 className="md-text">
            Your business is unique. You want your service provider to integrate into your existing processes seamlessly.<br />
            We do just that.
          </h2>
          <Link to="/services" className="explore xs-text bold">
            Explore all our services
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Services
